import { Card, CardContent, type CardRootProps } from "#ui/card";
import { Spinner } from "#ui/spinner";
import { cx } from "#ui/style.utils";

interface PendingCardContentProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "children"> {}

export function PendingCardContent({
  className,
  ...props
}: PendingCardContentProps) {
  return (
    <div
      {...props}
      className={cx(
        // Same classNames as ErrorCardContent - Keep in sync!
        "grid place-content-center place-items-center gap-20px text-center md:min-h-280px md:py-30px",
        className,
      )}
    >
      <Spinner />
    </div>
  );
}

interface PendingCardProps extends Omit<CardRootProps, "children"> {}

export function PendingCard(props: PendingCardProps) {
  return (
    <Card variant="white-border-and-blur" {...props}>
      <CardContent>
        <PendingCardContent />
      </CardContent>
    </Card>
  );
}
