"use client";

import { ErrorCardContent } from "#app/_ui/components/cards/ErrorCard";
import { PendingCardContent } from "#app/_ui/components/cards/PendingCard";
import { logError } from "#app/lib/logger";
import { useEffect } from "react";
import {
  useCreatePdf,
  type UseCreatePdfParams,
  type UseCreatePdfReturn,
} from "./pdf-utils";
import { PdfViewerIframe, type PdfViewerIframeProps } from "./PdfViewerIframe";

interface PdfViewerProps extends UseCreatePdfParams {
  className?: string;
  maxHeight?: PdfViewerIframeProps["maxHeight"];
  maxHeightOffset?: PdfViewerIframeProps["maxHeightOffset"];
  onValueChange?: (result: UseCreatePdfReturn) => void;
}

/** Usage (minimal props): `<PdfViewer queryFn={() => getSomeBlob(getSomeBlobParams)} />` */
export function PdfViewer({
  className,
  fileName,
  maxHeight,
  maxHeightOffset,
  onValueChange,
  queryFn,
}: PdfViewerProps) {
  const result = useCreatePdf({ fileName, queryFn });

  // Lift state if needed via the `onValueChange` callback prop.
  // Added `useEffect` to avoid "bad setState() call" Component Stack Error.
  useEffect(() => {
    if (onValueChange && result) {
      onValueChange(result);
    }
  }, [onValueChange, result]);

  if (result.status === "pending") {
    return <PendingCardContent />;
  }

  if (result.status === "error") {
    logError(result.error);
    return <ErrorCardContent />;
  }

  return (
    <PdfViewerIframe
      blobName={result.data.name}
      blobUrl={result.data.url}
      maxHeight={maxHeight}
      maxHeightOffset={maxHeightOffset}
      className={className}
    />
  );
}
