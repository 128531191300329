import { logError } from "#app/lib/logger";
import { useEffect, useState } from "react";

export interface UseCreatePdfParams {
  fileName?: string;
  queryFn: () => Promise<Blob>;
}

export interface UseCreatePdfDataSuccess {
  raw: Blob;
  url: string;
  name: string;
}

export type UseCreatePdfReturn =
  | { status: "pending"; data: null; error: null }
  | { status: "error"; data: null; error: string }
  | { status: "success"; data: UseCreatePdfDataSuccess; error: null };

/** Formats the name from the decoded path otherwise uses a fallback */
export function formatFileName(
  name: string | null | undefined,
  fallback: string,
) {
  if (!name) return fallback;
  const val = decodeURIComponent(name);
  const idxStart = val.lastIndexOf("/") + 1;
  return val.slice(idxStart).replace(".pdf", "").trim();
}

/** Creates a URL hash string from entries. */
export function createUrlHash(value: Array<[string, string | number]>) {
  return value.reduce((acc, cur, idx) => {
    acc = acc.concat((idx === 0 ? "#" : "&").concat(cur.join("=")));
    return acc;
  }, "");
}

export function useCreatePdf({
  fileName,
  queryFn,
}: UseCreatePdfParams): UseCreatePdfReturn {
  const [result, setResult] = useState<UseCreatePdfReturn>({
    status: "pending",
    data: null,
    error: null,
  });

  useEffect(() => {
    let ignore = false;

    queryFn()
      .then((blob) => {
        if (!ignore) {
          setResult({
            status: "success",
            data: {
              raw: blob,
              url: URL.createObjectURL(blob),
              name: formatFileName(fileName, "Untitled"),
            },
            error: null,
          });
        }
      })
      .catch((err) => {
        if (!ignore) {
          logError(err);
          setResult({
            status: "error",
            data: null,
            error: "Something went wrong.",
          });
        }
      });

    return () => {
      ignore = true;
    };
  }, [fileName, queryFn]);

  return result;
}
