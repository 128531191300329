"use client";

import { cx } from "#ui/style.utils";
import { createUrlHash } from "./pdf-utils";

export interface PdfViewerIframeProps
  extends Omit<
    React.ComponentPropsWithoutRef<"iframe">,
    "title" | "src" | "width" | "height"
  > {
  blobName: string;
  blobUrl: string;
  maxHeight?: number;
  maxHeightOffset?: number;
}

export function PdfViewerIframe({
  className,
  blobName,
  blobUrl,
  maxHeight = 800,
  maxHeightOffset = 260,
  ...props
}: PdfViewerIframeProps) {
  // Create the iframe src attribute
  const iframeSrc = blobUrl.concat(
    // Using entries instead of an object as the config
    // since PDF parameters are processed and executed from left to right
    createUrlHash([
      ["filename", blobName.replaceAll(" ", "-")], // avoid using or encoding spaces
      ["navpanes", 0], // sidebar (page or toc navigation)
      ["messages", 0],
      ["statusbar", 0],
      ["toolbar", 0], // topbar (pagination, zoom, download, print, etc.)
      ["view", "FitH"], // should/maybe help with removing ios horizontal scroll
    ]),
  );

  // Resize iframe height based on window height (prevents nested scrollbars)
  // Does NOT watch for resizes.
  let iframeHeight = `${maxHeight}px`;
  if (typeof globalThis !== "undefined") {
    const iframeDiff = globalThis.innerHeight - maxHeightOffset;
    if (iframeDiff < maxHeight) {
      iframeHeight = `${iframeDiff}px`;
    }
  }

  return (
    <iframe
      {...props}
      title={`Viewing PDF "${blobName}"`}
      src={iframeSrc}
      width="100%"
      height={iframeHeight}
      className={cx("border-y border-y-shade-15 bg-shade-15", className)}
    />
  );
}
